import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const images = [
  {
    original: "/about/01-min.jpg",
    thumbnail: "/about/01-min.jpg",
  },
  {
    original: "/about/02-min.jpg",
    thumbnail: "/about/02-min.jpg",
  },
  {
    original: "/about/03-min.jpg",
    thumbnail: "/about/03-min.jpg",
  },
  
];
class Speakers extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section
        className="speakers-area-two"
        style={{ paddingTop: 30 }}
        id="speaker"
      >
        <div className="container">
        <div className="section-title">
              
                {/* <h3>Celebrating 8 Years of Innovation</h3> */}
                <div className="bar"></div>
                {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
              </div>
          <div className="row">
            <div className="col-lg-8 col-sm-6" style={{ fontSize: 18 }}>
            <h2 style={{ color: "#3D72C1" }}>
                  Experience the CIT ALUMNI 1996!
                </h2>
              {/* <div className="single-speakers-box"> */}
              <p style={{ fontSize: 16,textAlign:"justify",textJustify:"inter-word" }}>
              Join us for a nostalgic trip down memory lane as we celebrate the 1996 batch reunion! It's been 27 years since we last gathered, and it's time to reconnect, reminisce, and create new memories together. Catch up with old friends, share stories of our college days, and make this reunion an unforgettable event. Get ready for an evening of laughter, joy, and the opportunity to rekindle the bonds that were forged in 1996. Don't miss this chance to relive the past and make new memories with your batchmates!
             
              </p>
              {/* <p></p> */}
              {/* <p>Despite the ongoing challenges in the marketplace, I am thrilled to see the positive momentum in our Hitachi Vantara partner business as we head into 2023. Together, we have the power to address the most critical business challenges surrounding agility, data access, insights, and hybrid cloud strategy. Our goal is to provide predictable, stable, and efficient solutions that empower businesses to thrive.</p> */}
              {/* <p>The Hitachi Vantara India Partner Summit has firmly established itself as a must-attend conference for our partners. This year, the event will be held from July 12th to 14th in the enchanting city of Siliguri, West Bengal. It presents a unique opportunity for you to gain front-row access to emerging technologies such as cloud, object storage, Internet of Things (IoT), and much more.</p> */}
              {/* <p>As a valued partner, your participation in this summit is an opportunity to expand your value by leveraging the diverse range of Hitachi Vantara offerings. You will have the chance to meet and interact with our esteemed leadership team and forge collaborative alliances with fellow partners, enabling us to jointly sell our innovative solutions.</p> */}
              {/* <p>We firmly believe that partnering with Hitachi Vantara brings together the best of both worlds – leading technology, rich incentives for greater profitability, and a spirit of collaboration and teamwork that will undoubtedly propel you to success.</p> */}
              {/* <p>We are excited to have you join us at the Hitachi Vantara India Partner Summit 2023. Let us seize this opportunity to shape the future of business together. Together, we can achieve remarkable heights.</p> */}
              {/* <div className="speakers-image">
                                    <img 
                                        src={require("../../assets/images/speakers1.jpg")}
                                        alt="speaker" 
                                    />
                                </div>

                                <div className="speakers-content">
                                    <h3><Link to="#">James Anderson</Link></h3>
                                    <span>Founder & CEO</span>

                                    
                                </div> */}
              {/* </div> */}
            </div>
            <div className="col-lg-4 col-sm-6" style={{display:"flex",alignItems:"center"}}>
              <div style={{display:"flex",justifyContent:"center"}}>
                <ImageGallery
                 showThumbnails={false}
                 autoPlay={true}
                 lazyLoad={true}
                 showNav={false}
                 disableArrowKeys={false}
                 showFullscreenButton={false}
                 showPlayButton={false}
                 items={images}
                />

                {/* <div className="speakers-content">
                                    <h3><Link to="#">Hemant Tewari</Link></h3>
                                    <span>Head of Commercial Sales, APAC</span>

                                    
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Speakers;
