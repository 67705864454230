// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config =  {
  apiKey: "AIzaSyAXwFs5LOoSDoLtKYBz0PG-hGq8WMf7TcI",
  authDomain: "citalumni1996.firebaseapp.com",
  databaseURL: "https://citalumni1996-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "citalumni1996",
  storageBucket: "citalumni1996.appspot.com",
  messagingSenderId: "282813874178",
  appId: "1:282813874178:web:3b8409a2c8d4ab67aa671b",
  measurementId: "G-SBPCBLG45L"
};

firebase.initializeApp(config)
firebase.analytics();