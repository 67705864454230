import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../history";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import { isMobile, withOrientationChange } from "react-device-detect";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";

// import 'react-datetime-picker/dist/DateTimePicker.css';
import "react-datepicker/dist/react-datepicker.css";

// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';
class Registerform extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    airporttransfer: null,
    city: "",
    position: "",
    checked2: false,
    modal: false,
    company: "",
    phone: "",
    photo: null,
    photo2: null,
    isloading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "",
    relation: null,
    checked: false,
    dietrist: "",
    anyother:"",
    category: null,
    startDate: new Date("2023/07/12"),
    checkoutDate: new Date("2023/07/14"),
    tshirt: null,
    aadhardownloadurl: null,
    pricetopay: 0,
    eventregtype:"",
    additionbed: false,
    sightseeing: false,
    hotelroomprice:0,
    childroomprice:0,
    eventregprice:0,
    additionbedprice:0,
    airporttransferprice:0,
    localtransprice:0
  };
  // const [value, onChange] = useState(new Date());
  onChange = (data) => {
    this.setState({ startDate: data });
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
    // history.push("/login");
  };
  handleAlert2 = (state, value) => {
    this.setState({ [state]: value });
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  componentDidMount() {
    // this.processpayment();
  }
  processpayment = () => {
    // this.setState({
    //   paymentloading: true,
    // });

    var currency = "INR";
    var prtopay =this.state.eventregprice+ this.state.additionbedprice+this.state.childroomprice+this.state.hotelroomprice+this.state.airporttransferprice+this.state.localtransprice;

    var price = prtopay + 0.035 * prtopay;
    // price=1;
    var createOrder = firebase
      .app()
      .functions()
      .httpsCallable("createOrderandcheckemail");
    createOrder({
      amount: price * 100,
      currency: "INR",
      email: this.state.email,
    })
      .then((result) => {
        // this.setState({
        //   paymentloading: false,
        // });
        console.log(result);
        console.log(result.data);
        console.log(this.state);
        if (result.data.err === 0) {
          console.log("got order id");
          var options = {
            // key: "rzp_test_gt9p38Tz3xqRcE",

            key: "rzp_live_FtYpxo8ZgA9VTe",
            // "key": "rzp_test_2JHOUGP2nwGlXo",
            amount: price * 100, // Example: 2000 paise = INR 20
            name: "CIT ALUMNI 1996",
            // "description": "description",

            order_id: result.data.order.id,
            // "image": "img/logo.png",// COMPANY LOGO

            handler: (response) => {
              console.log(response);

              this.setState(
                {
                  paymentdone: response,
                },
                () => {
                  this.completeregistration();
                  // alert(JSON.stringify(this.state.finaldata));
                }
              );
              // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
            },
            modal: {
              ondismiss: () => {
                console.log("dismissed");
                this.setState({
                  isloading: false,
                });
                //  window.location.replace("//put your redirect URL");
              },
            },
            prefill: {
              name: this.state.firstname, // pass customer name
              email: this.state.email, // customer email
              // "contact": '+919123456780' //customer phone no.
            },
            // "notes": {
            //   "address": "address" //customer address
            // },
            theme: {
              color: "#00A1E0", // screen color
            },
          };
          console.log(options);
          var propay = new window.Razorpay(options);
          propay.open();
          propay.on("", () => {});
          propay.on("payment.failed", (response) => {
            console.log(response);
            console.log("Payment canceled");
            if (response.error.reason === "NA") {
            } else {
              this.setState({
                errorAlert: true,
                errormsg: response.error.reason,
              });
            }

            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
          // this.setState({
          //   isloading: false,
          //   activeStep: 1,
          //   email: values.email
          // })
        } else if (result.data.err === 2) {
          this.setState({
            isloading: false,
            errorAlert: true,
            errormsg: "Email Already exists",
          });
        } else {
          this.setState({
            isloading: false,
            errorAlert: true,
            errormsg: "There was an error, Please retry in sometime",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("There was an error please retry");
        this.setState({
          isloading: false,
        });
      });
  };
  completeregistration() {
    var prtopay =this.state.eventregprice+ this.state.additionbedprice+this.state.childroomprice+this.state.hotelroomprice+this.state.airporttransferprice+this.state.localtransprice;

    // var price = prtopay + 0.035 * prtopay;

    var registeruser = firebase
      .app()
      .functions("asia-east2")
      .httpsCallable("createuser");
    registeruser({
      paymentdone: this.state.paymentdone,
      title: this.state.title,
      firstname: this.state.name,
      lastname: this.state.lastname,
      branches: this.state.branches.value,
      aadhar: this.state.aadhardownloadurl,
      email: this.state.email,
      phone: this.state.phone,
      eventregtype:this.state.eventregtype,
      organisation: this.state.organisation,
      designation: this.state.designation,
      meal: this.state.meal,
      tshirtsize: this.state.tshirtsize.value,
      pricetopay: prtopay + 0.035 * prtopay,
      airporttransfer: this.state.airporttransfer,

      sightseeing: this.state.sightseeing,
      // tshirtother: this.state.tshirtother,
      // tshirt: this.state.tshirt.value,
      dietrist: this.state.dietrist ? this.state.dietrist : "",
      anyother: this.state.anyother ? this.state.anyother : "",

      withfamily: this.state.withfamily,
      occupancy: this.state.occupancy,
      // firstoccupancyname: this.state.firstoccupancyname,
      secondoccupancyname: this.state.secondoccupancyname,
      secondoccupancyage: this.state.secondoccupancyage,
      thirdoccupancyname: this.state.thirdoccupancyname,
      thirdoccupancyage: this.state.thirdoccupancyage,
      child:this.state.childadd?this.state.childadd.value:0,
      // onward: this.state.onward.value,
      // return: this.state.return.value,
      // onwardother: this.state.onwardother,

      // returnother: this.state.returnother,
      // phone: this.state.phone,
      // email: this.state.email,
      time: new Date().toString(),
      // plusone: this.state.plusone,
      // compfirstname: this.state.compfirstname?this.state.compfirstname:"",
      // complastname: this.state.complastname?this.state.complastname:"",
      // relation: this.state.relation ? this.state.relation.value : "",
      // password: this.state.password
    }).then((data) => {
      console.log(data);
      console.log(data.data.err);
      console.log(JSON.stringify(data));
      if (data.data.err == 0) {
        console.log("REgister done");
        this.setState({
          successAlert: true,
          modal: false,

          isloading: false,
          title: this.state.title,
          name: "",
          withfamily: null,
          occupancy: null,
          firstoccupancyname: "",
          secondoccupancyname: "",
          thirdoccupancyname: "",
          thirdoccupancyage: "",
          secondoccupancyage: "",
          airporttransfer: null,
          lastname: "",
          photo: null,
          photo2: null,
          title: null,
          email: "",
          phone: "",
          eventregtype:"",
          organisation: "",
          sightseeing: false,
          checked: false,
          designation: "",
          meal: null,
          dietrist: "",
          anyother:"",
          onward: null,
          return: null,
          onwardother: "",
          returnother: "",
          childroomprice:0,
          hotelroomprice:0,
          eventregprice:0,
          additionbedprice:0,
          airporttransferprice:0,
          localtransprice:0
        });
      } else if (data.data.err === 1) {
        console.log("Already registered");
        this.setState({
          errorAlert: true,
          errormsg: "Already Registered",
          isloading: false,
        });
      } else {
        console.log("There was an error");
        this.setState({
          errorAlert: true,
          errormsg: "There was an error",
          isloading: false,
        });
      }
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.occupancy);
    console.log(this.state.photo);
    if (this.state.occupancy&& this.state.photo === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please add Aadhaar card/ Passport/ Driving Licence.",
      });
    } else if (this.state.branches === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select your branch.",
      });
    }else if (this.state.tshirtsize === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select your T-shirt size.",
      });
    }
    // else if (this.state.email.endsWith("@framez.sg")&&this.state.email.endsWith("@netradyne.com")) {
    //   this.setState({
    //     errorAlert: true,
    //     errormsg: "Please use your Company Email Id.",
    //   });
    // }
    else {
      this.setState({
        isloading: true,
      });
      if(this.state.photo){
        var fileext = this.state.filetype.includes("png") ? ".png" : ".jpg";
        firebase
          .storage()
          .ref("files/" + new Date().getTime() + fileext)
          .putString(
            this.state.photo.replace(/^data:image\/[a-z]+;base64,/, ""),
            "base64",
            { contentType: this.state.filetype }
          )
          // .put(this.state.photo)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at1", downloadURL);
  
              this.setState(
                {
                  aadhardownloadurl: downloadURL,
                },
                () => {
                  this.processpayment();
                }
              );
            });
          });
      }else{
        this.processpayment();
      }
     
    }
  };
  toggleModal2 = () => {
    this.setState((prevState) => ({
      modal2: !prevState.modal2,
    }));
  };
  render() {
    var prtopay =this.state.eventregprice+this.state.additionbedprice+this.state.childroomprice+this.state.hotelroomprice+this.state.airporttransferprice+this.state.localtransprice;
    console.log(prtopay);
    return (
      <section className="signup-area">
          <Modal isOpen={this.state.modal2} toggle={this.toggleModal2}>
          <ModalHeader toggle={this.toggleModal2}>Size Chart</ModalHeader>
          <ModalBody>
            <img src="/jack.jpg" width={"100%"} />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Confirmation!!!</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onSubmit}>
              <div class="form-check" style={{ marginTop: 10 }}>
                <input
                  required
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    this.setState({
                      checked2: !this.state.checked2,
                    });
                  }}
                  id="flexCheckChecked2"
                  checked={this.state.checked2}
                />
                <label class="form-check-label" for="flexCheckChecked2">
                  I accept that all the given information is best to my
                  knowledge and aware that the same will be used for all the
                  necessary bookings.
                </label>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  disabled={this.state.isloading}
                  type="submit"
                  style={{ zIndex: 0 }}
                  className="btn btn-primary"
                >
                  {this.state.isloading ? <Spinner color="red" /> : "Register"}
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <SweetAlert
          success
          title="Success"
          style={{ zIndex: 10000 }}
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for your registration. Anticipate receiving a confirmation
            email soon.
          </p>
        </SweetAlert>

        <SweetAlert
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert2("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div>
          <div className="signup-form">
            {/* <h3>Create your Account</h3> */}
            {/* <h3>Registrations are now closed for the summit</h3> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.occupancy&& this.state.photo === null) {
                  this.setState({
                    errorAlert: true,
                    errormsg: "Please add Aadhaar card/ Passport/ Driving Licence.",
                  });
                }
                // else if (this.state.email.endsWith("@framez.sg")&&this.state.email.endsWith("@netradyne.com")) {
                //   this.setState({
                //     errorAlert: true,
                //     errormsg: "Please use your Company Email Id.",
                //   });
                // }
                else {
                  this.setState({
                    modal: true,
                  });
                }
              }}
            >
               <div className="form-group">
                <label style={{ marginRight: 20 }}>Type of Event Registration </label>
                <br />
                <div class="form-check form-check-inline">
                  <input
                    required
                    onChange={(e) => this.setState({ eventregtype: "Single",eventregprice:10000})}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions43"
                    checked={this.state.eventregtype === "Single"}
                    id="14234"
                    value="option11"
                  />
                  <label class="form-check-label" for="14234">
                    Single Guest – (INR 10000)
                  </label>
                </div>
                <br />
                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ eventregtype: "Double",eventregprice:12500 })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions43"
                    checked={this.state.eventregtype === "Double"}
                    id="3123123"
                    value="option22"
                  />
                  <label class="form-check-label" for="3123123">
                    Guest +1 - (INR 12500)
                  </label>
                </div>
                <br />
                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ eventregtype: "Triple",eventregprice:15000 })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions43"
                    checked={this.state.eventregtype === "Triple"}
                    id="inlineRadio223321313"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio223321313">
                    Guest +2 - (INR 15000)
                  </label>
                  <p>* Registration without stay and one goodie bag only</p>

                </div>


              </div>
              {/* <p>* Registration without stay and one goodie bag only</p> */}

              <div className="form-group">
                <label style={{ marginRight: 20 }}>Title </label>
                <div class="form-check form-check-inline">
                  <input
                    required
                    onChange={(e) => this.setState({ title: "Mr." })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.title === "Mr."}
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Mr.
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ title: "Ms." })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.title === "Ms."}
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Ms.
                  </label>
                </div>
              </div>
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Contact Number (Please add country code)"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="E-mail id"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Organisation Name"
                  value={this.state.organisation}
                  onChange={(e) =>
                    this.setState({ organisation: e.target.value })
                  }
                />
              </div> */}
              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Designation"
                  value={this.state.designation}
                  onChange={(e) =>
                    this.setState({ designation: e.target.value })
                  }
                />
              </div> */}

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="City of departure"
                  value={this.state.citydepart}
                  onChange={(e) =>
                    this.setState({ citydepart: e.target.value })
                  }
                />
              </div> */}
              <div className="form-group">
                <label style={{ marginRight: 20 }}>Meal preference </label>
                <div class="form-check form-check-inline">
                  <input
                    required
                    onChange={(e) => this.setState({ meal: "Vegetarian" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    checked={this.state.meal === "Vegetarian"}
                    id="inlineRadio11"
                    value="option11"
                  />
                  <label class="form-check-label" for="inlineRadio11">
                    Vegetarian
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ meal: "Non-Vegetarian" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    checked={this.state.meal === "Non-Vegetarian"}
                    id="inlineRadio22"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio22">
                    Non-Vegetarian
                  </label>
                </div>
              </div>
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.branches}
                  onChange={(a) => {
                    this.setState({
                      branches: a,
                    });
                  }}
                  placeholder="Please select your branch"
                  options={[
                    {
                      value: "CSE",
                      label: "CSE",
                    },
                    {
                      value: "ECE",
                      label: "ECE",
                    },
                    {
                      value: "EEE",
                      label: "EEE",
                    },
                    {
                      value: "CHEM",
                      label: "CHEM",
                    },
                    {
                      value: "MECH",
                      label: "MECH",
                    },
                    {
                      value: "CIVIL",
                      label: "CIVIL",
                    },
                  ]}
                />
              </div>
             
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Any dietary restriction"
                  value={this.state.dietrist}
                  onChange={(e) => this.setState({ dietrist: e.target.value })}
                />
              </div>
              <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label style={{ marginRight: 20 }}>T-shirt </label>
                      {/* <h5 style={{fontSize:15}}>T-shirt</h5> */}
                      <h7
                        onClick={() => {
                          console.log("modal2 opening");
                          this.setState({
                            modal2: true,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#cc0000",
                          float: "right",
                        }}
                      >
                        Size Chart
                      </h7>
                    </div>
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.tshirtsize}
                  onChange={(a) => {
                    this.setState({
                      tshirtsize: a,
                    });
                  }}
                  placeholder="T-shirt Size (UK)"
                  options={[
                    {
                      value: "XS 36",
                      label: "XS 36",
                    },
                    {
                      value: "S 38",
                      label: "S 38",
                    },
                    {
                      value: "M 40",
                      label: "M 40",
                    },
                    {
                      value: "L 42",
                      label: "L 42",
                    },
                    {
                      value: "XL 44",
                      label: "XL 44",
                    },
                    {
                      value: "XXL 46",
                      label: "XXL 46",
                    },
                    {
                      value: "XXL 48",
                      label: "XXL 48",
                    },
                  ]}
                />
              </div>
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="T-shirt Size – cm only"
                  value={this.state.tshirtsize}
                  onChange={(e) =>
                    this.setState({ tshirtsize: e.target.value })
                  }
                />
              </div> */}
               <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Any Other Requirements"
                  value={this.state.anyother}
                  onChange={(e) => this.setState({ anyother: e.target.value })}
                />
              </div>
              {this.state.photo ? (
                <div style={{ padding: 10 }}>
                  <img width={"100%"} src={this.state.photo} />
                </div>
              ) : null}
              <div style={{ display: "flex", flex: 1,alignItems:"center" }}>
                <p style={{ textAlign: "justify", flex: 2 }}>
                  For hotel reservations - Please upload your Aadhaar card/ Passport/ Driving Licence/ A Govt issued photo ID<br/>
                (Please upload a single file for both back and front)
                </p>
                <input
                  id="myInput"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    console.log(file);
                    if (file.size > 4097152) {
                      alert("Please select file size less than 4mb");
                    } else {
                      console.log(file.type);
                      if (
                        !(
                          file.type.includes("png") ||
                          // file.type.includes("pdf") ||
                          file.type.includes("jpg") ||
                          file.type.includes("jpeg")
                        )
                      ) {
                        alert("Please select only JPG or PNG file");
                      } else {
                        var imagebase64 = "";
                        var reader = new FileReader();
                        reader.onloadend = () => {
                          imagebase64 = reader.result;
                          this.setState({
                            photo: imagebase64,
                            filetype: file.type,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                    // this.setState({file});
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    marginTop: 10,
                    marginLeft:10
                  }}
                >
                  <div
                    className="registerbutton2"
                    disabled={this.state.isloading}
                    color="warning"
                    style={{
                      margin: 10,
                      // flex: 1,
                      paddingTop: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      height: 30,
                    }}
                    type="submit"
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    Upload
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label style={{ marginRight: 20, marginTop: 20 }}>
                  With family
                </label>
                <div class="form-check form-check-inline">
                  <input
                    
                    onChange={(e) => this.setState({ withfamily: "Yes" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3"
                    checked={this.state.withfamily === "Yes"}
                    id="inlineRadio11112"
                    value="option11"
                  />
                  <label class="form-check-label" for="inlineRadio11112">
                    Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ withfamily: "No" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3"
                    checked={this.state.withfamily === "No"}
                    id="inlineRadio2233"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio2233">
                    No
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label style={{ marginRight: 20 }}>Occupancy (Check In - 19th Check Out - 21st)</label>
                <br />
                <div class="form-check form-check-inline">
                  <input
                    
                    onChange={(e) => this.setState({ occupancy: "Single",hotelroomprice:5000 *2,childadd:null,childroomprice:0})}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions431"
                    checked={this.state.occupancy === "Single"}
                    id="142341"
                    value="option11"
                  />
                  <label class="form-check-label" for="142341">
                    Single – (INR 5000   per day)
                    {/* | USD 61 */}
                   
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ occupancy: "Double",hotelroomprice:5700 *2,childadd:null,childroomprice:0 })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions431"
                    checked={this.state.occupancy === "Double"}
                    id="31231231"
                    value="option22"
                  />
                  <label class="form-check-label" for="31231231">
                    Double - (INR 5700  per day)
                    {/* | USD 69  */}
                    
                  </label>
                </div>
                {/* <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ occupancy: "Triple",hotelroomprice:7000 *2 })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions431"
                    checked={this.state.occupancy === "Triple"}
                    id="inlineRadio2233213131"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio2233213131">
                    Triple - (INR 7000 | USD 85 per day)
                  </label>
                </div> */}

                <div class="form-check form-check-inline">
                  <input
                    onChange={(e) => this.setState({ occupancy: "Sharing",hotelroomprice:2850*2 ,childadd:null,childroomprice:0 })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions431"
                    checked={this.state.occupancy === "Sharing"}
                    id="inlineRadio223321313111"
                    value="option22"
                  />
                  <label class="form-check-label" for="inlineRadio223321313111">
                    Sharing - (INR 2850  per day)
                    {/* | USD 35 */}
                     
                  </label>
                  <br/>*Please note the room will be on sharing basis with other colleague
                </div>
              </div>
             
              {/* {this.state.occupancy==="Single"||this.state.occupancy==="Double"||this.state.occupancy==="Triple"?


              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First person Name"
                  value={this.state.firstoccupancyname}
                  onChange={(e) =>
                    this.setState({ firstoccupancyname: e.target.value })
                  }
                />
              </div> :null} */}

              {this.state.occupancy === "Double" ||
              this.state.occupancy === "Triple" ? (
                <>
                  <div className="form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Second Guest Name"
                      value={this.state.secondoccupancyname}
                      onChange={(e) =>
                        this.setState({ secondoccupancyname: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Second Guest Age"
                      value={this.state.secondoccupancyage}
                      onChange={(e) =>
                        this.setState({ secondoccupancyage: e.target.value })
                      }
                    />
                  </div>
                </>
              ) : null}

              {this.state.occupancy === "Triple" ? (
                <>
                  <div className="form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Third Guest Name"
                      value={this.state.thirdoccupancyname}
                      onChange={(e) =>
                        this.setState({ thirdoccupancyname: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Third Guest Age"
                      value={this.state.thirdoccupancyage}
                      onChange={(e) =>
                        this.setState({ thirdoccupancyage: e.target.value })
                      }
                    />
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      onChange={(e) => {
                        this.setState({
                          additionbed: !this.state.additionbed,
                          additionbedprice: this.state.additionbed
                            ? 0
                            :  1000,
                        });
                      }}
                      id="flexCheckChecked"
                      checked={this.state.additionbed}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Additional bed required (INR 1000 applicable)
                    </label>
                  </div>
                </>
              ) : null}
               {this.state.occupancy==="Single"||this.state.occupancy==="Double"?
              <div className="form-group">
              <label >
              Children - (INR 1300 
              {/* | USD 15  */}
               per day per Child)
                  </label>
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={this.state.childadd}
                  onChange={(a) => {
                    console.log(a.value);
                    console.log((2*a.value*1300))
                    this.setState({
                      childadd:a,
                    
                      childroomprice:2*(parseInt(a.value))*1300
                    });
                  }}
                  placeholder="0"
                  options={[
                    {
                      value: 0,
                      label: "0",
                    },
                    {
                      value: 1,
                      label: "1",
                    },
                    {
                      value: 2,
                      label: "2",
                    },
                    {
                      value: 3,
                      label: "3",
                    },
                    {
                      value: 4,
                      label: "4",
                    }
                  ]}
                />
                              <p>*As per hotel policy only 1 extra bed will be provided</p>

              </div>:null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                {/* <h3>Air ticket itinerary</h3> */}
                <p style={{ textAlign: "left" }}>
                  <b>Mandatory Gala Dinner inclusive of the below:</b>
                  <br />
                  a. Venue rental <br />
                  b. Veg and Non-Veg buffet dinner with starters <br />
                  c. Soft Beverages for 90 minutes <br />
                  *Liquor can be ordered directly at the venue*
                </p>
                <br />
                <h6 style={{ textAlign: "left" }}>Transportation</h6>
                <div className="form-group">
                  <label style={{ marginRight: 20, marginTop: 20 }}>
                    Airport transfers{" "}
                  </label>
                  <div class="form-check form-check-inline">
                    <input
                      
                      onChange={(e) =>{
                        if(this.state.airporttransfer==="Sedan"){
                          this.setState({ airporttransfer: null,airporttransferprice:0 })
                        }else{
                          
                            this.setState({ airporttransfer: "Sedan",airporttransferprice:2000 })

                          
                        }
                      }
                      }
                      class="form-check-input"
                      type="checkbox"
                      name="inlineRadioOptions99"
                      checked={this.state.airporttransfer === "Sedan"}
                      id="inlineRadio1111211"
                      value="option1111"
                    />
                    <label class="form-check-label" for="inlineRadio1111211">
                      Sedan (for upto 3 passengers with 2 pc luggage)<br/> – (INR
                      2000 
                      {/* | USD 24 */}
                      ) Both ways
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                    
                      onChange={(e) =>{
                        if(this.state.airporttransfer==="Innova"){
                          this.setState({ airporttransfer: null,airporttransferprice:0});

                        }else{
                         
                          this.setState({ airporttransfer: "Innova",airporttransferprice:3000 })
                          
                        }
                        
                      }
                        
                      }
                      class="form-check-input"
                      type="checkbox"
                      name="inlineRadioOptions99"
                      checked={this.state.airporttransfer === "Innova"}
                      id="inlineRadio223322"
                      value="option22223"
                    />
                    <label class="form-check-label" for="inlineRadio223322">
                      Innova (for upto 4 passengers with 4 pc luggage)<br/> – (INR
                      3000 
                      {/* | USD 36 */}
                      ) Both ways
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    this.setState({
                      sightseeing: !this.state.sightseeing,
                      localtransprice: this.state.sightseeing
                        ? 0
                        : 4500,
                    });
                  }}
                  id="flexCheckCheck111ed"
                  checked={this.state.sightseeing}
                />
                <label class="form-check-label" for="flexCheckCheck111ed">
                  For Sightseeing, applicable to use locally <br />
                  a. For 4 hours / 40km – (INR 4500 
                  {/* | USD 55 */}
                  ) <br />
                  b. Extra hour cost - additionally applicable <br />
                  c. Extra km cost - additionally applicable
                  <br />
                </label>
              </div>
              <p>
                Please note: For payment via credit card or international
                transactions – there is a bank fee 3.5% applicable
              </p>
              {/* <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={this.state.onward}
                  onChange={(a) => {
                    this.setState({
                      onward: a,
                    });
                  }}
                  placeholder="Onward Journey"
                  options={[
                    {
                      value: "Bengaluru",
                      label: "Bengaluru | 6E 113 | 9:00 - 10:05 | 1H 5M",
                    },
                    {
                      value: "Mumbai",
                      label: "Mumbai | 6E 6701 | 8:55 - 10:50 | 1H 55M",
                    },
                    {
                      value: "Chennai",
                      label: "Chennai | 6E 5394 | 8:10 - 9:25 | 1H 15M",
                    },
                    {
                      value: "Delhi",
                      label: "Delhi | UK 883 | 6:00 - 9:05 | 3H 5M",
                    },
                    {
                      value: "Hyderabad",
                      label: "Hyderabad |  6E 637 | 7:00 - 8:35 | 1H 35M",
                    },
                    {
                      value: "Pune",
                      label: "Pune | 6E 352 & 6E 6707 | 6:25 - 11:20 | 4H 55M",
                    },
                    {
                      value: "Vadodara",
                      label:
                        "Vadodara | AI 670 & AI 693 | 7:00 - 14:05 | 7H 5M",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </div>
              {this.state.onward && this.state.onward.value === "Other" ? (
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Onward Journey City"
                    value={this.state.onwardother}
                    onChange={(e) =>
                      this.setState({ onwardother: e.target.value })
                    }
                  />
                </div>
              ) : null}
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={this.state.return}
                  onChange={(a) => {
                    this.setState({
                      return: a,
                    });
                  }}
                  placeholder="Return Journey"
                  options={[
                    {
                      value: "Bengaluru",
                      label: "Bengaluru | 6E 196 | 11:15 - 12:25 | 1H 10M",
                    },
                    {
                      value: "Mumbai",
                      label: "Mumbai | UK 516 | 10:10 - 12:30 | 2H 20M",
                    },
                    {
                      value: "Chennai",
                      label: "Chennai | 6E 571 | 12:45 - 13:55 | 1H 10M",
                    },
                    {
                      value: "Delhi",
                      label: "Delhi | 6E 2382 | 16:00 - 19:20 | 3H 20M",
                    },
                    {
                      value: "Hyderabad",
                      label: "Hyderabad |  6E 304 | 11:50 - 13:10 | 1H 20M",
                    },
                    {
                      value: "Pune",
                      label: "Pune | 6E 571 & 6E 183 | 12:45 - 17:20 | 4H 35M",
                    },
                    {
                      value: "Vadodara",
                      label:
                        "Vadodara | 6E 196 & 6E 807 | 11:15 - 17:50 | 6H 35M",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </div>
              {this.state.return && this.state.return.value === "Other" ? (
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Please Enter Return Journey City"
                    value={this.state.returnother}
                    onChange={(e) =>
                      this.setState({ returnother: e.target.value })
                    }
                  />
                </div>
              ) : null} */}
              {/* <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      > */}

              {/* </div> */}
              {/* <div
                        style={{
                          flex: 1,
                        }}
                      > */}
              {/* <div class="form-check" style={{ marginTop: 10 }}>
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    this.setState({
                      checked: !this.state.checked,
                    });
                  }}
                  id="flexCheckChecked"
                  checked={this.state.checked}
                />
                <label class="form-check-label" for="flexCheckChecked">
                  I accept that deduction of tax as per 194R a TDS at the rate
                  of 10% will be debited and recover from the individual /
                  entity via a debit note towards the tour cost.
                </label>
              </div> */}
              {/* {this.state.photo2 ? (
                <div style={{ padding: 10 }}>
                  <img width={"100%"} src={this.state.photo2} />
                </div>
              ) : null} */}
              {/* <div style={{ display: "flex", flex: 1 }}>
               
                {this.state.checked ? (
                  <>
                    <p style={{ textAlign: "justify", flex: 2 }}>
                      To support 194R please upload your Pan-card
                    </p>
                    <input
                      id="myInput"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      ref={(ref) => (this.upload2 = ref)}
                      style={{ display: "none" }}
                      onChange={(event) => {
                        var file = event.target.files[0];
                        console.log(file);
                        if (file.size > 2097152) {
                          alert("Please select file size less than 2mb");
                        } else {
                          if (
                            !(
                              file.type.includes("png") ||
                              file.type.includes("jpg") ||
                              file.type.includes("jpeg")
                            )
                          ) {
                            alert("Please select only JPG or PNG file");
                          } else {
                            var imagebase64 = "";
                            var reader = new FileReader();
                            reader.onloadend = () => {
                              imagebase64 = reader.result;
                              this.setState({
                                photo2: imagebase64,
                                filetype2: file.type,
                              });
                            };
                          }
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    <div style={{ flex: 1 }}>
                      <div
                        className="registerbutton3"
                        disabled={this.state.isloading}
                        style={{
                          margin: 10,
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: 20,
                          height: 30,
                        }}
                        type="submit"
                        onClick={() => {
                          this.upload2.click();
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </>
                ) : null}
              
              </div> */}

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  value={this.state.position}
                  onChange={(e) => this.setState({ position: e.target.value })}
                />
              </div> */}

              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion First Name"
                  value={this.state.compfirstname}
                  onChange={(e) => this.setState({ compfirstname: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion Last Name"
                  value={this.state.complastname}
                  onChange={(e) => this.setState({ complastname: e.target.value })}
                />
              </div> */}
              {/* <p style={{ textAlign: "justify", marginBottom: 10 }}>
                Section 194R is towards Deduction of tax on benefit or
                perquisite in respect of business or profession - Travel scheme
                accounts for benefit or perquisite in case of person travelling
                in its individual capacity or it accounts for the benefit in
                case of an entity if the person travelling represents the
                entity. TDS at the rate of 10% will be debited and recovered
                from the individual / entity via a debit note of its share
                towards the tour cost.{" "}
              </p> */}

              <button
                disabled={this.state.isloading}
                type="submit"
                style={{ zIndex: 0 }}
                className="btn btn-primary"
              >
                {this.state.isloading ? (
                  <Spinner color="red" />
                ) : (
                  "Register " +
                  (prtopay > 0
                    ? "& Pay Rs." +
                      (prtopay + 0.035 * prtopay)
                    : "")
                )}
              </button>
              <button style={{marginTop:30}} onClick={()=>{
                window.open("https://forms.gle/mV6xaBuQXVcLm8Lk7")
             }}  className="btn btn-primary">Click here to update your additional information</button>
              

              {/* <p>
                  Already a registered user? <Link to="/login">Login!</Link>
                </p> */}
            </form>
          </div>
          {/* </div> */}
        </div>
      </section>
    );
  }
}

export default Registerform;
