import React from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
// import Footer from '../Common/Footer';
 
class Faq extends React.Component {
    submitHandler = (e) => {
        e.preventDefault();
    }
    render(){
        return (
            <React.Fragment>
                {/* <div className="page-title-area item-bg2">
                    <div className="container">
                        <h1>FAQ</h1>
                        <span>Frequently asked Questions</span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>FAQ</li>
                        </ul>
                    </div>
                </div> */}

                <section id="faq" className="faq-area ptb-120">
                    <div className="container">
                       
                        <div className="faq-accordion">
                             <h1>FAQ</h1>
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        When and where is the CIT 96 Batch Reunion?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Date: 20th and 21st July 2024<br/>
Venue: CIT, Coimbatore
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Who can attend?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        All Engineering graduates who passed out in the year 1996 and their immediate family members are welcome to attend the event                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How to attend the event?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Please register with attendee’s details including family members and book your place for the event. There is a small fee to cover the event expenses that you can pay at the time of registration.                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How much should I pay to attend the event?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Registration charges = INR 10,000. This is to cover the event related expenses for a single person
Hotel accommodation will be additional and can be paid on the website at preferred rates negotiated with Radisson, Coimbatore.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Who else is attending the reunion?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        All your batchmates from 1996 will be attending along with family/Single. Please contact your branch coordinator for further details                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Where do we stay when we visit during the event?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Rooms have been blocked at Radisson, Coimbatore at preferred rates. Pls register and look for Hotel Accommodation details in the homepage. You can book your room and pay on the website directly at the preferred rates.                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How do we cancel the booking?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        We hope you attend the event without fail. However, in the highly unlikely situation you must have to cancel, pls send your cancel request to admin at support@framez.sg                                         </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is the Cancellation policy?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        a) Registration can be cancelled for full refund till 30 Apr 2024. There will be no refund for cancellations after 30 Apr  and the registration fees paid will be taken up as Sponsorship contribution towards the event.<br/>

b) Hotel accommodation can be cancelled till 30 Jun 2024 for full refund. There will be no refund for cancellations after 30 Jun and the hotel charges paid will be taken up as Sponsorship contribution towards the event.

</p>
                                    </AccordionItemPanel>
                                </AccordionItem>



                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Can I get Hotel accommodation before 19th Jul 2024 and go sightseeing around Coimbatore?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                        Anyone who needs hotel accommodation prior to 19th Jul can contact Karnan, Muthu (US), Gopalswamy, Sudharsan (Mech). <br/>

Sightseeing can be arranged by the travel partner if there is a group of batchmates interested in it. To discuss more details, contact Karnan, Muthukumar P (US), Gopalswamy, Sudharsan (Mech)
</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                       
                    </div>
                </section>

                {/* <Footer /> */}
            </React.Fragment>
        );
    }
}
 
export default Faq;