import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Placestovisit extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section id="speaker2" className="speakers-area ptb-120 pb-0">
            <div className="container">
                <div className="section-title">
                    {/* <span>Listen to the Event Speakers</span> */}
                    <h2>PLaces to visit</h2>
                    <div className="bar"></div>
                    {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                    {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
                </div>
            </div>
            {/* <h5 style={{textAlign:"center"}}>Will be announced soon</h5> */}
            <div className="row m-0">
            <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/adiyogi.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Adiyogi - The Source of Yoga</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
               
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/siruvani.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Siruvani Falls and Dam</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/amuthaas.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Ghee Roast Dosa at Hotel Sri Amuthaas</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers" style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/arisi.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Arisi paruppu sadam at Viruksham Parambariya Unavagam</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/gedee.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Gedee car museum</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/marudhamalai.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Marudhamalai Temple</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/palli.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Pallipalayam Chicken at Nani's Kitchen</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
               
               
                
                <div className="col-lg-3 col-md-6 p-0">
                    <div className="single-speakers"  style={{height:"100%"}}>
                        <img 
                         style={{height:"100%"}}
                            src={"/places/kon.jpg"} 
                            alt="speakers" 
                        />

                        <div className="speakers-content">
                            <h3><Link style={{color:"white"}} to="#">Kongunadu Biryani at Famous Biryani House</Link></h3>
                            {/* <span>Founder & CEO</span> */}
                        </div>
                        
                    </div>
                </div>
               
              
                {/* <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                <img src={"/places/adiyogi.jpg"} alt="Speaker" />
                   
                </div> */}
             

                

                

             
                
            </div>
        </section>
        );
    }
}
 
export default Placestovisit;