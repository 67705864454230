import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const images = [
  {
    original: "/hotel/hotel1.jpg",
    thumbnail: "/hotel/hotel1.jpg",
  },
];
class Footer extends React.Component {
  state = {
    modal2: false,
    successAlert: false,
    collapsed: true,
    isOpen: false,
    modal: false,
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Contact US</ModalHeader>
          <ModalBody>
            {/* Contact us */}
            <div>
              For queries please contact us
              <br /><br/>
              Sourabh Saini
              <br />
              <a href="mailto:sourabh.saini@framez.sg">
              sourabh.saini@framez.sg
              </a>
              <br />
              <a href="tel:+916366554301">
              +91 6366554301
              </a>
               <br />
              Frame Events and Productions
              <br />
              B2, Ganga, KHB colony, 17th cross, koramangala 5th block,
              Bangalore 560095
            </div>
            {/* <img src="/tshirt.jpg" width={"100%"} /> */}
          </ModalBody>
        </Modal>
        <section className="about-area ptb-120 bg-image" id="about1">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-7">
                <div
                  className="about-content"
                  style={{ fontSize: 18, textAlign: "center" }}
                >
                  <h3>Join us at Radisson Blu Coimbatore</h3>
                  <br />
                  <p style={{ fontSize: 18, textAlign: "justify" }}>
                    Radisson Blu Coimbatore is a luxurious hotel situated in
                    Coimbatore, India. Known for its sophisticated ambiance and
                    exceptional hospitality, the hotel offers a range of upscale
                    amenities, including well-appointed rooms, fine dining
                    restaurants serving a variety of cuisines, a rooftop
                    swimming pool, a fitness center, spa facilities, and modern
                    event spaces suitable for conferences, weddings, and other
                    gatherings. Its convenient location near the airport and
                    major attractions makes it an ideal choice for both business
                    and leisure travelers seeking comfort and convenience in
                    Coimbatore.{" "}
                  </p>

                  <h6>
                    Distance: From Coimbatore International Airport to hotel:
                    6.6km
                  </h6>
                  {/* <button
                    to="/pricing-1"
                    className="btn btn-primary"
                    onClick={() => {
                      window.open(
                        "https://www.marriott.com/en-us/hotels/blrsw-sheraton-grand-bengaluru-whitefield-hotel-and-convention-center/overview/"
                      );
                    }}
                  >
                    MORE ABOUT THE HOTEL  
                  </button> */}
                </div>
              </div>

              <div className="col-lg-5">
                <div className="about-image">
                  {/* <img src="./hotel1.jpg" className="about-img1" alt="about" /> */}

                  {/* <img src="./hotel3.jpg" className="demo" alt="about" /> */}
                  <ImageGallery
                    showThumbnails={false}
                    autoPlay={true}
                    lazyLoad={true}
                    showNav={false}
                    disableArrowKeys={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={images}
                  />

                  {/* <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" /> */}

                  {/* <LaxButton buttonText="Explore More About" /> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div
                  className="single-footer-widget"
                  style={{
                    color: "#3D72C1",
                    fontSize: 18,
                    // display: "flex",
                    // justifyContent: "center",
                    // flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <h3 style={{ color: "#3D72C1" }}>Venue</h3>
                  <span style={{ color: "#3D72C1" }}>
                    <i
                      style={{ color: "#3D72C1" }}
                      className="icofont-calendar"
                    ></i>{" "}
                    19th-21st July, 2024
                  </span>

                  <p
                    className="location"
                    style={{ color: "#3D72C1", fontSize: 18 }}
                  >
                    <i className="icofont-google-map"></i> Radisson Blu
                    Coimbatore
                  </p>

                  <a
                    to="/contact"
                    style={{ color: "#3D72C1" }}
                    className="contact-authority"
                  >
                    <i className="icofont-phone"></i> For any event related support:
                    <br />
                    subbiah.swaminathan.pmp@gmail.com<br/>
+91 9940523809<br/><br/>

Contact Us:<br/>
 support@framez.sg<br/>
 +91 6366554301<br/>
                    <div style={{ textAlign: "center", color: "black" }}>
                      <h3>Contact Information:</h3>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                        }}
                      >
                        <div style={{ margin: "10px" }}>
                          <h4>Civil Engineering:</h4>
                          <p style={{ color: "#000" }}>
                            Karnan Ramamurthy (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919840246995"
                            >
                              +91 9840246995
                            </a>
                          </p>
                        </div>
                        <div style={{ margin: "10px" }}>
                          <h4>Chemical Engineering:</h4>
                          <p style={{ color: "#000" }}>
                            Vaideeswaran (Kuwait){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+96597225107"
                            >
                              +965 9722 5107
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Thirumaran (Kuwait){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+96565138026"
                            >
                              +965 6513 8026
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Mahesh (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+918499926276"
                            >
                              +91 8499926276
                            </a>
                          </p>
                        </div>
                        <div style={{ margin: "10px" }}>
                          <h4>
                          Electronics and Communications Engineering (ECE):
                          </h4>
                          <p style={{ color: "#000" }}>
                            Rajkumar (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919445962505"
                            >
                              +91 9445962505
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Kamal (US){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+16192006038"
                            >
                              +1 6192006038
                            </a>
                          </p>
                        </div>
                        <div style={{ margin: "10px" }}>
                          <h4>Electrical and Electronics Engineering (EEE):</h4>
                          <p style={{ color: "#000" }}>
                            Sabapathy (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919958922113"
                            >
                              +91 9958922113
                            </a>
                          </p>
                        </div>
                        <div style={{ margin: "10px" }}>
                          <h4>Computer Science and Engineering (CSE):</h4>
                          <p style={{ color: "#000" }}>
                            Ponraj (UK){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+447872021584"
                            >
                              +44 7872021584
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Khalil (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919884416105"
                            >
                              +91 9884416105
                            </a>
                          </p>
                        </div>
                        <div style={{ margin: "10px" }}>
                          <h4>Mechanical Engineering:</h4>
                          <p style={{ color: "#000" }}>
                            Susiendran (US){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+13015249146"
                            >
                              +1 (301) 524-9146
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Sudarshan (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919791159426"
                            >
                              +91 9791159426
                            </a>
                          </p>
                          <p style={{ color: "#000" }}>
                            Venkateswaralu (India){" "}
                            <a
                              style={{ color: "#333" }}
                              href="tel:+919884744898"
                            >
                              +91 9884744898
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <a  style={{ color: "#3D72C1",marginLeft:25}} href="mailto:juliet.nadar@framez.sg">juliet.nadar@framez.sg</a> */}
                    <div
                      style={{
                        color: "#3D72C1",
                        marginLeft: 25,
                        display: "none",
                      }}
                    >
                      Civil - Karnan Ramamurthy (India) +91 9840246995
                      <br />
                      Chemical - Vaideeswaran (Kuwait )+96597225107, Thirumaran
                      (Kuwait)+965 6513 8026, Mahesh (India) +918499926276
                      <br />
                      ECE - Rajkumar (India) +919445962505, Kamal (US) +1
                      334-296-2875
                      <br />
                      EEE - Sabapathy (India) +919958922113
                      <br />
                      CSE - Ponraj (UK) 004-478-72021584, Khalil (India)
                      +919884416105
                      <br />
                      Mechanical - Susiendran (US) +1 (301) 524-9146, Sudarshan
                      (India) +919791159426, Venkateswaralu (India)
                      +919884744898
                      <br />
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <a target="_blank" href="/terms.pdf">
                        Terms and Conditions
                      </a>
                      <a
                        style={{ marginLeft: 20 }}
                        target="_blank"
                        href="/privacy.pdf"
                      >
                        Privacy policy
                      </a>
                      <a
                        style={{ marginLeft: 20 }}
                        target="_blank"
                        href="/refund.pdf"
                      >
                        Refund/cancellation policy
                      </a>
                      <a
                        style={{ marginLeft: 20 }}
                        onClick={() => {
                          // alert("Hello");
                          console.log("Hello");
                          this.setState({
                            modal: true,
                          });
                        }}
                      >
                        Contact us
                      </a>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6" style={{ display: "none" }}>
                <div className="single-footer-widget">
                  <h3>Social Connection</h3>
                  {/* <p>Don't miss Link thing! Receive daily news You should connect social area for Any Proper Updates Anytime.</p> */}

                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/HitachiVantara"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HitachiVantara"
                        className="twitter"
                        target="_blank"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hitachi-vantara/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/HitachiVantara"
                        className="youtube"
                        target="_blank"
                      >
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="copyright-area">
                  {/* <div className="logo">
                    <a
                      href="https://www.hitachivantara.com/"
                      target="_blank"
                      to="/"
                    >
                      <img
                        src={require("../../assets/images/hitachi4.png")}
                        width={200}
                        alt="logo"
                      />
                    </a>
                  </div> */}
                  {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                  <p style={{ color: "#3D72C1" }}>
                    Copyright <i className="icofont-copyright"></i>{" "}
                    {new Date().getFullYear()} Framez. All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
