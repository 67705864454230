import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const images = [
  {
    original: "/about/01-min.jpg",
    thumbnail: "/about/01-min.jpg",
  },
  {
    original: "/about/02-min.jpg",
    thumbnail: "/about/02-min.jpg",
  },
  {
    original: "/about/03-min.jpg",
    thumbnail: "/about/03-min.jpg",
  },
  
];
class Sponsor extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section
        className="speakers-area-two"
        style={{ paddingTop: 30 }}
        id="sponsor"
      >
        <div className="container">
        <div className="section-title">
              
                {/* <h3>Celebrating 8 Years of Innovation</h3> */}
                <div className="bar"></div>
                {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
              </div>
          <div className="row">
            <div className="col-lg-8 col-sm-6" style={{ fontSize: 18 }}>
            <h2 style={{ color: "#3D72C1" }}>
            Sponsorship Contribution 

                </h2>
                {/* <h4>Usage of contribution towards below Activities</h4> */}
              {/* <div className="single-speakers-box"> */}
              <p style={{ fontSize: 16,textAlign:"justify",textJustify:"inter-word" }}>
              {/* <br/> Usage of contribution towards below Activities<br/><br/>
              Branding and Fabrication (Event Branding, Stage Setup etc)<br/>
Event Registartion & Onsite Registartion<br/>
Event AV (Sound, light, Led and other technical)<br/>
Giveaways / Goodies (for all attendees)<br/>
Felicitation (Staff & Non Support Staff)<br/>
Sculpture Erection<br/>
Transport & Logistics<br/>
Food & Beverage <br/>
Photography & Videography<br/> 
Enteratinment (Live Performances)    <br/>
*Sponsored activity will be allocated after collection          */}


Dear Friends,<br/>

Our batchmates would love to see you at the Reunion on 20th July 2024 at CIT. In the event you could not attend for any reason, please share the joy by sponsoring the event in one of the following ways. Your sponsorship will mean a lot to all of us, especially to some of our dear friends who would otherwise not be able to afford to attend the event. <br/>

<ol>
  <li>Bring a smile on  another batchmate by paying INR 10,000 towards his/her registration.</li>
  <button onClick={()=>{
                window.open("https://rzp.io/l/CwxffY4M")
             }}  className="btn btn-primary">Contribute Now</button>
  <li>Sponsor towards entertainment expenses by paying INR 5,000</li>
  <button onClick={()=>{
                window.open("https://rzp.io/l/UABPAKs0N")
             }}  className="btn btn-primary">Contribute Now</button>
  <li>Sponsor a gift by paying INR 3,000</li>
  <button onClick={()=>{
                window.open("https://rzp.io/l/rGcrNIS3aV")
             }}  className="btn btn-primary">Contribute Now</button>

<li>Enter donation amount of your choice </li>
  <button onClick={()=>{
                window.open("https://rzp.io/l/t8egeur")
             }}  className="btn btn-primary">Contribute Now</button>
             <br/><br/>
  Contact us for more information <br/>(email: <a href="mailto:rkarnan@gmail.com">rkarnan@gmail.com</a>; Phone:<a href="tel:+91 9840246995">+91 9840246995</a>)

</ol>
{/* 
Be a sponsor, co-design an unforgettable odyssey:<br/>

- ⭐ Recreate our alma mater's essence with branding, spirited stages, and iconic sculptures.<br/>
- ⭐ Simplify mingling with seamless registration, efficient logistics, and nostalgic transportation.<br/>
- ⭐ Illuminate the night with lights, sound, acknowledgements, and eternal keepsakes.<br/>
- ⭐ Capture moments with professional photography and personalized mementos.<br/>
- ⭐ Experience alumni talent on stage, igniting shared passion.<br/>

Choose to sponsor facets like food, stage, branding, registration, giveaways, and more. Your sponsorship weaves magic, leaving an indelible mark on our legacy.<br/>

Let's redefine reunions, creating a tale etched in legends. Join us in making this the most remarkable CIT clan return ever!<br/>

Contact our committee to start your sponsorship journey. Let's set this reunion ablaze!<br/>

Shine on, Torchbearers!<br/>

P.S. Remember those late-night hostel room chats? We've got you covered.<br/> */}
              </p>
             {/* <button onClick={()=>{
                window.open("https://rzp.io/l/framez")
             }}  className="btn btn-primary">Contribute Now</button> */}
              {/* <p></p> */}
              {/* <p>Despite the ongoing challenges in the marketplace, I am thrilled to see the positive momentum in our Hitachi Vantara partner business as we head into 2023. Together, we have the power to address the most critical business challenges surrounding agility, data access, insights, and hybrid cloud strategy. Our goal is to provide predictable, stable, and efficient solutions that empower businesses to thrive.</p> */}
              {/* <p>The Hitachi Vantara India Partner Summit has firmly established itself as a must-attend conference for our partners. This year, the event will be held from July 12th to 14th in the enchanting city of Siliguri, West Bengal. It presents a unique opportunity for you to gain front-row access to emerging technologies such as cloud, object storage, Internet of Things (IoT), and much more.</p> */}
              {/* <p>As a valued partner, your participation in this summit is an opportunity to expand your value by leveraging the diverse range of Hitachi Vantara offerings. You will have the chance to meet and interact with our esteemed leadership team and forge collaborative alliances with fellow partners, enabling us to jointly sell our innovative solutions.</p> */}
              {/* <p>We firmly believe that partnering with Hitachi Vantara brings together the best of both worlds – leading technology, rich incentives for greater profitability, and a spirit of collaboration and teamwork that will undoubtedly propel you to success.</p> */}
              {/* <p>We are excited to have you join us at the Hitachi Vantara India Partner Summit 2023. Let us seize this opportunity to shape the future of business together. Together, we can achieve remarkable heights.</p> */}
              {/* <div className="speakers-image">
                                    <img 
                                        src={require("../../assets/images/speakers1.jpg")}
                                        alt="speaker" 
                                    />
                                </div>

                                <div className="speakers-content">
                                    <h3><Link to="#">James Anderson</Link></h3>
                                    <span>Founder & CEO</span>

                                    
                                </div> */}
              {/* </div> */}
            </div>
            <div className="col-lg-4 col-sm-6" style={{display:"flex",alignItems:"center"}}>
              <div style={{display:"flex",justifyContent:"center"}}>
                <ImageGallery
                 showThumbnails={false}
                 autoPlay={true}
                 lazyLoad={true}
                 showNav={false}
                 disableArrowKeys={false}
                 showFullscreenButton={false}
                 showPlayButton={false}
                 items={images}
                />

                {/* <div className="speakers-content">
                                    <h3><Link to="#">Hemant Tewari</Link></h3>
                                    <span>Head of Commercial Sales, APAC</span>

                                    
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Sponsor;
